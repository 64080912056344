
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './views/Home';
import Contact from './views/Contact';
// import Resume from './views/Resume';
import NoPage from './views/NoPage';

import FromLink from './views/intros/FromLink';
import FromQr from './views/intros/FromQr';
import FromIg from './views/intros/FromIg';

// import UnderConstruction from './views/UnderConstruction';
// import logo from './logo512.png';

export default function App(){
  return(
    <div className="App font-face-cm" >
       <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="contact" element={<Contact />} />
            {/* <Route path="resume" element={<Resume />} /> */}

            <Route path="link" element={<FromLink />} /> 
            <Route path="qr" element={<FromQr />} />
            <Route path="ig" element={<FromIg />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
       </header>
     </div>
    
  );
}

const downloadResume = () => {

  fetch('SamplePDF.pdf').then(response => {
      response.blob().then(blob => {

          const fileURL = window.URL.createObjectURL(blob);

          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'bedirhan-z-elban.pdf';
          alink.click();
      })
  })
}

export {downloadResume};

