const FromIg = () => {
    return FromIgIntro();
}


function FromIgIntro() {
    return(
        <div>
            <h1>FromIg</h1>
        </div>
    );
}


export default FromIg;