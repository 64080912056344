const FromQr = () => {
    return FromQrIntro();
}


function FromQrIntro() {
    return(
        <div>
            <h1>FromQr</h1>
        </div>
    );
}


export default FromQr;