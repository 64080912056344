import { Link } from "react-router-dom"

import logo from '../assets/logo192.webp';

const NoPage = () => {
    return NotFoundView();
}

function NotFoundView() {
  return (
    <div className="App font-face-cm" >
      <header className="App-header"> 
      <img src={logo} alt="logo" />
      <div className='font-face-cm' >
      <p>not found</p>
          <Link to="/" className="link">home</Link>
      </div>
    </header>
    </div>
  );
}

export default NoPage;