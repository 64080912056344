const FromLink = () => {
    return FromLinkIntro();
}


function FromLinkIntro() {
    return(
        <div>
            <h1>FromLink</h1>
        </div>
    );
}


export default FromLink;