

import { Link } from 'react-router-dom';


const Home = () => {
    return HomeView();
}


function HomeView(){
    return(
        <div classname="Home font-face-cm" >
            <h1>bedirhan z. elban</h1>
            <p>{'|> software engineer'} </p>
            <p>{'|> cloud-native developer'} </p>
            <p>{'|> iiot, automation, robotics enthusiast'} </p>
            <div className='container' >
                {/* <div>
                    <Link to="resume" className="link">resume</Link>
                </div>  */}
                <div>
                    <Link to="contact" className="link">contact</Link>
                </div> 
            </div>
        </div>
    );
}

export default Home;