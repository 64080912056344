import { Link } from "react-router-dom";

const Contact = () => {
  return ContactView();
}

function ContactView() {

  return (
    <div className="Contact">
      <h1>contact me</h1>
      <p>bedirhanzelban@proton.me</p>
      <p>+90 545 368 72 13</p>
      <div>
        <Link to="https://www.linkedin.com/in/elban/" className="link">linkedin</Link>
      </div>
      <div>
        <Link to="https://keybase.io/bzelban" className="link">keybase</Link>
      </div>
      <div>
          <Link to="https://github.com/bzelban" className="link">github</Link>
      </div> 
     
      <p></p>
      <div className="container">
      {/* <div>
          <Link to="resume" className="link">resume</Link>
        </div>  */}
        <div>
          <Link to="/" className="link">home</Link>
        </div> 
      </div>
    </div>
  );
}

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
}




export default Contact;